<template lang="pug">
  base-page
    template(v-slot:titlu)
      span Cereri de concediu
    template(v-slot:leftmenu)
      el-tooltip(content="Export zip")
        el-button(type='warning' @click="exportXml" icon='el-icon-folder' size='small' circle)
    el-card
      el-form(label-position="top" :inline="false" label-width="100%"  :model='Filters'  ref='my-form' )
        el-row(:gutter="24")
          el-col(:span='6')
            el-form-item( label="Data start" )
              el-date-picker( v-model="Filters.Data" type="daterange" value-format="yyyy-MM-dd" format="yyyy-MM-dd")
      div.top50
        el-button(type='success' @click="refresh_info") Filtreaza
        el-button(type='danger' @click="reset()") Reset
    el-table( :data="Formulare" height="calc(100vh - 150px)" v-loading='loadingVisible' :row-class-name="getRowStyle")
      el-table-column( label="Angajat" prop="NumeAngajat" width='300')
      el-table-column( prop="DataInceputConcediu" label="Data inceput"  min-width="200")
      el-table-column( prop="DataSfarsitConcediu" label="Data sfarsit" min-width="200")
      el-table-column( prop="NumarZileConcediu"  label="Numar zile"  min-width="200")
      el-table-column( prop="DataCreare"     label="Data creare"  min-width="200")
      el-table-column( prop="Aprobat"     label="Status"  min-width="200")

      el-table-column( fixed="right" label="Actiuni" width="200px" )
        template( slot-scope="scope" )
          div(v-if="scope.row.Aprobat == 'Pending'")
            el-tooltip( content="Aproba" )
              el-button( type="success" size='mini' icon="el-icon-check"  circle @click="aproba_cerere(scope.row.Id)" )
            el-tooltip( content="Respinge" )
              el-button( type="danger" size='mini' icon="el-icon-error" circle @click="respinge_cerere(scope.row.Id)"    )
            el-tooltip( content="Anuleaza" v-if="verificaDataConcediu(scope.row.DataInceputConcediu) && scope.row.Aprobat != 'Anulat' && scope.row.Aprobat != 'Respins'" )
              el-button( type="warning" size='mini' icon="el-icon-error" circle @click="anuleaza_cerere(scope.row.Id)"    ) 
          div(v-else)
            el-tooltip( content="Anuleaza" v-if="verificaDataConcediu(scope.row.DataInceputConcediu) && scope.row.Aprobat != 'Anulat' && scope.row.Aprobat != 'Respins'" )
              el-button( type="warning" size='mini' icon="el-icon-error" circle @click="anuleaza_cerere(scope.row.Id)"    )
            el-tooltip( content="Descarca" v-if="scope.row.Aprobat != 'Anulat' && scope.row.Aprobat != 'Respins'" )
             el-button( type="primary" size='mini' icon="el-icon-download" circle @click="descarca_cerere(scope.row.Id)"    )

    el-pagination( @size-change= "refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager")
</template>

<script>
import { now } from 'moment';
import settings from "../backend/LocalSettings";
import BasePage from "../pages/BasePage";
import TitluPagina from '../widgets/TitluPagina';
import moment from 'moment';

export default {
  name: "cereri-concediu",
  extends: BasePage,
  components: {
      'base-page': BasePage,
      'titlu-pagina': TitluPagina,
  },
  data () {
    return { 
      Results: [],
      base_url: '',
      Formulare: [],
      Filters: { Nume: '', Tag: '', Status:'activi', IdDepartament: -1, Data: ['', ''] },
      OrderBy: { Column:"", Order:"ascending" },
      PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
    }
  },
  methods: {
    async refresh_info() {
      var response        = await this.post("formular/get_cereri_concediu", { Filters: this.Filters } );
      this.Formulare = response.Formulare;
      console.log(this.Formulare);
      console.log();

      this.select_menu_item('cereri-concediu');
    },
    reset() {
      var dateFrom = moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
      var dateTo = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      this.Filters = { Nume: '', Tag: '', Status:'activi', IdDepartament: -1, Data: [dateFrom, dateTo] },
      this.refresh_info();
    },
    async aproba_cerere(id){
      var confirm =  await this.$confirm(`Esti sigur ca vrei sa aprobi cererea de concediu?`, 'Warning');
      if( confirm ){
        //  window.open('https://secure.bytimer.com/api/index.php/formular/aproba?id=' + id, '_blank');
        // console.log(id);
        let res = await this.post('formular/aproba', {
          id: id
        });
        console.log(res);
        this.refresh_info();
      }
       
    },
    async respinge_cerere(id){
      var confirm =  await this.$confirm(`Esti sigur ca vrei sa respingi cererea de concediu?`, 'Warning');
      if( confirm ){
        //  window.open('https://secure.bytimer.com/api/index.php/formular/respinge?id=' + id, '_blank');
        // console.log(id);
        let res = await this.post('formular/respinge', {
          id: id
        });
        console.log(res);
        this.refresh_info();
      }
       
    },
    async anuleaza_cerere(id){
      var confirm =  await this.$confirm(`Esti sigur ca vrei sa anulezi cererea de concediu?`, 'Warning');
      if( confirm ){
        let res = await this.post('formular/anuleaza', {
          id: id
        });
        console.log(res);
        this.refresh_info();
      }
      
    },
    getRowStyle({row}){
        if (row.Aprobat === 'Aprobat') {
            return 'success-row';
        } else if (row.Aprobat === 'Respins') {
            return 'warning-row';
        } else if (row.Aprobat === 'Anulat') {
          return 'gray-row';
        }
        return '';
    },
    verificaDataConcediu(DataInceputConcediu){
      return !(new Date(DataInceputConcediu) < new Date());
    },
    descarca_cerere(id) {
      var windowUrl = `${this.base_url}formular/descarcaCerereConcediu?id=${id}`
      window.open(windowUrl, '_blank')
    },
    exportXml() {
      let filters = JSON.stringify( this.Filters )
      filters = btoa(filters)
      filters = encodeURI( filters )
      let windowUrl = `${this.base_url}/formular/exportZip/${settings.get_token()}?filters=${filters}`;
      window.open(windowUrl, '_blank')
    }
  },
  mounted(){
    settings.verify_login_and_redirect(this);
    
    this.base_url = settings.get_base_url();
    this.reset();
  }
};
</script>

<style lang="less">

  .top50{
    margin-top: 20px;
  }

 .warning-row{ 

    background: rgb(255, 201, 201) !important;
  }

 .success-row {
    background: rgb(148, 245, 148) !important;
  }
  .gray-row {
    background: rgb(198, 198, 198) !important;
  }
 
</style>
